$(document).on('ready turbolinks:load', function() {

  $('.url-review').change(function(){
    str = $(this).val();

    if (str.includes("http") != true) {
      str = 'http://' + str;

      $(this).val(str);
      $('.hint', $(this).parent('div')).html('Attention: We noticed an incomplete url, so we made a modification. Does the above url look correct?');
    }
  });

  const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
  const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))

  $('#user_estimated_monthly_net_cashflow').addClass('field-required');
  
  $('.field-required').change(function(){
    var form             = $(this).closest('form'),
        button           = $('input[type="submit"]', form),
        incomplete_count = 0;

    $('.field-required', form).each(function(i, obj) {
      var empty = ($(this).val() == '');

      if (empty) {
        incomplete_count += 1;

        button.attr('disabled', 'disabled');

        console.log('asdf')
      }
    });

    if (incomplete_count == 0) {
      $('input[type="submit"]', form).removeAttr('disabled');
    }
  }).change();

  $('#user_experience_start_month').change(function(){
    value = $(this).val();

    $('#user_experience_start_date_2i').val(value);
    console.log(value);
  }).change();

  $('#user_experience_start_year').change(function(){
    value = $(this).val();

    $('#user_experience_start_date_1i').val(value);
    console.log(value);
  }).change();

  $("input.currency-input").keyup(function(event){
    // skip for arrow keys
    if(event.which >= 37 && event.which <= 40){
      event.preventDefault();
    }

    var $this = $(this);
    var num   = $this.val().split(".")[0].toString().replace(/[^0-9]/g, '');
    var num2  = num.replace(/,/gi, "");
  	var num3  = num2.split(/(?=(?:\d{3})+$)/).join(",");
    
    console.log($this.val());
    console.log(num);
    console.log(num2);
    console.log(num3);
    
    // the following line has been simplified. Revision history contains original.
    $this.val(num3);
  }).keyup();

  $('.toggle-trigger').click(function(event){
    event.preventDefault();
    target = $(this).data('toggleable');

    if ($(target).hasClass('d-none')) {
      $(target).removeClass('d-none');
    } else {
      $(target).toggle();
    }
  });

  $(".alerts").delay(5000).slideUp(500);

  $('form').one('submit', function(e) {
    event.preventDefault();

    $('.currency-input').each(function() {
      var clean = $(this).val().split(",").join("")

      $(this).val(clean);
    });

    $(this).submit();
  });

  $('form').on('click', '.remove_fields', function(event) {
    $(this).prev('input[type=hidden]').val('1');
    $(this).closest('fieldset').hide();
    return event.preventDefault();
  });

  $('form').on('click', '.add_fields', function(event) {
    var regexp, time;
    time = new Date().getTime();
    regexp = new RegExp($(this).data('id'), 'g');
    $(this).before($(this).data('fields').replace(regexp, time));
    return event.preventDefault();
  });
});
